const BASE_URL = process.env.REACT_APP_API_HOSTNAME;

export const GET_QUESTIONS_URL = `${BASE_URL}/GetQuestions`;
export const GET_COUNTRIES_SUBGROUP_RAW_URL = `${BASE_URL}/GetCountriesSubGroupRaw`;
export const GET_COUNTRY_SUBGROUP_RAW_URL = `${BASE_URL}/GetCountrySubGroupRaw`;
export const GET_OVERVIEW_URL = `${BASE_URL}/getoverview`;
export const GET_COUNTRY_OVERVIEW_URL = `${BASE_URL}/GetCountryOverview`;
export const GET_COUNTRY_ANSWER_URL = `${BASE_URL}/getcountryanswers`;
export const GET_STATUS_2020_SUBMISSION_URL = `${BASE_URL}/getStatus2020Submission`;
export const GET_COUNTRY_BASIC_INFO_URL = `${BASE_URL}/GetCountryBasicInfo`;
export const GET_PUBLICATIONSREPORTS_URL = `${BASE_URL}/GetPublicationsReports`;
export const GET_HOME_SLIDER_URL = `${BASE_URL}/GetHomeSlider`;
export const GET_HOME_STORY_URL = `${BASE_URL}/GetHomeStory`;
export const GET_HOME_DESC_URL = `${BASE_URL}/GetHomeDescription`;
export const GET_SP_STORY_URL = `${BASE_URL}/GetSupportProgrammeStory`;
export const GET_SP_HEADING_URL = `${BASE_URL}/GetSupportProgrammeHeading`;
export const GET_SP_BOXES_URL = `${BASE_URL}/GetSupportProgrammeBoxes`;
export const GET_ABOUT_BOXES_URL = `${BASE_URL}/GetAboutBoxes`;
export const DOWNLOAD_BASELINE_SUMMARY_URL = `${BASE_URL}/DownloadBaselineSummaryFile`;
export const DOWNLOAD_NATIONAL_SUBMISSION_URL = `${BASE_URL}/GetNationalSubmissionFile`;
export const DOWNLOAD_SURVEY_DOC_URL = `${BASE_URL}/DownloadSurveyDocument`;
export const DOWNLOAD_ALL_QUESTIONARE_METODOLOGIES_URL = `${BASE_URL}/DownloadAllQuestionareAndMetodologies`;
export const DOWNLOAD_COUNTRY_FACTSHEETS_PILOTS_URL = `${BASE_URL}/DownloadCountryFactsheetsPilots`;
export const DOWNLOAD_ON_ABOUT_PAGE_URL = `${BASE_URL}/DownloadOnAboutPage`;
export const DOWNLOAD_REPORTING_ROUND_URL = `${BASE_URL}/DownloadReportingRound`;
export const DOWNLOAD_REPORTING_SUMMARY_URL = `${BASE_URL}/DownloadReportingSummary`;
export const DOWNLOAD_COUNTRY_DATABASE_FULL_URL = `${BASE_URL}/DownloadCountryDatabaseFull`;
export const OPEN_GUIDELINES_FILE_URL = `${BASE_URL}/OpenPdfGuidelines`;
export const GET_PUBLICATION_REPORTS_NO_IMAGE_URL = `${BASE_URL}/getPublicationsReportsNoImages`;
export const GET_PUBLICATION_REPORTS_IMAGE_URL = `${BASE_URL}/getPublicationsReportsImages`;
export const GET_PUBLICATION_REPORTS_PARAMETER_BY_TYPE = `${BASE_URL}/GetPublicationsReportsParametersByType`;
export const SEND_EMAIL = `${BASE_URL}/SendEmail`;
export const DOWNLOAD_FULL_DATABASE_EXCEL = `${BASE_URL}/DownloadPublicationsReportDoc/English/SDG_651_2017-2023_Final_02042024.xlsx`
export const DOC_URL = 'https://iwrmdataportal.unepdhi.org/Documents';
export const STAKEHOLDER_URL = `${BASE_URL}/DownloadCountryStakeholder`;
export const ACTION_PLANS_URL = `${BASE_URL}/DownloadCountryActionPlan`;
export const GET_SUPPORT_PROGRAMME_CONTENTS = `${BASE_URL}/GetSupportProgrammeContents`;
export const GET_SUPPORT_PROGRAMME_MEDIA = `${BASE_URL}/GetActiveSupportProgrammeMedia/1`;
export const GET_ACTIVE_PUBLICATION_TYPES = `${BASE_URL}/GetActivePublicationTypes`;