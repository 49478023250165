import { Container } from '@mui/system';
import Grid from '@mui/system/Unstable_Grid';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import UN_Environment_Logo from '../../assets/logo/un-environment-grey.png'

const Admin = () => {
  return (
    <>
      <Container style={{width: '100%', height: '100px', backgroundColor: '#00AEEF'}} maxWidth={false}>
        <Grid container>
          <Grid xs={8}>
            <Box sx={{ display: 'flex', height: '100px', alignItems: 'center' }}>
              <img style={{width: '100px', height: '100px'}} src={UN_Environment_Logo} alt='' />
              <Typography variant='body1' style={{padding: '0 15px', color: '#FFFFFF'}}>Administration Page</Typography>
            </Box>
          </Grid>
          <Grid xs={4}>
            <Box sx={{ display: 'flex', height: '100px', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Typography variant='body1' style={{padding: '0 10px', color: '#FFFFFF'}}>Anna Downsky</Typography>
              <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#FFFFFF', width: '35px', height: '35px', borderRadius: '50px'}}>
                <Typography style={{fontSize:'20px', fontWeight: 450, color: '#00AEEF'}}>AD</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth={false} style={{width: '100%', height: 'calc(100% + 170px', position: 'absolute', padding: '0 0 20px', overflowY: 'auto'}}>
        Main Content
      </Container>
    </>
  )
}

export default Admin