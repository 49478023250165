import { SET_PUBLICATION_DATA } from './usePublicationAction';

const initialState = {
    Global: null,
    Regional: null,
    Methodology: null,
    Thematic: null,
    PolicyBriefs: null,
    ExtraResources: null
};

const publicationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PUBLICATION_DATA:
            return {
                ...state,
                [action.payload.type]: action.payload.data
            };
        default:
            return state;
    }
};

export default publicationReducer;