import React, { Fragment, useState, useCallback, useEffect } from 'react'
import { Helmet } from 'react-helmet';
import {GET_SUPPORT_PROGRAMME_CONTENTS, GET_SUPPORT_PROGRAMME_MEDIA} from '../../api/IWRMServices'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import parse from 'html-react-parser';

const customeStyle = {
  accordionHeader: {
    backgroundColor: '#F2F5F7',
    borderBottom: '2px solid lightgray'
  },
  accordionTitle: {
    color: '#09334B',
    fontSize: 14,
    fontWeight: 700
  },
  accordionContent: {
    fontSize: 12,
    fontWeight: 300,
    color: '#09334B',
    marginBottom: 10
  }
}

const Support = () => {

  const [expanded, setExpanded] = useState('panel1');
  const [data, setData] = useState([]);
  const [media, setMedia] = useState('');

  const supportsContents = useCallback(async () => {
    try {
      let res = await fetch(GET_SUPPORT_PROGRAMME_CONTENTS);
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      const data = await res.json();
      return data;
    } catch (error) {
      console.error('Error fetching support programme contents:', error);
      return null;
    }
  }, []);

  const supportsMedia = useCallback(async () => {
    try {
      let res = await fetch(GET_SUPPORT_PROGRAMME_MEDIA);
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      const data = await res.json();
      return data;
    } catch (error) {
      console.error('Error fetching support programme contents:', error);
      return null;
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const result = await supportsContents();
      if (result) {
        setData(result);
      }
    };
    fetchData();
  }, [supportsContents]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await supportsMedia();
      if (result) {
        setMedia(result);
      }
    };
    fetchData();
  }, [supportsMedia]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  
  return (
    <Fragment>
      <Helmet>
        <title>IWRM Data Portal - Support Programme</title>
        <meta name="description" content="what is SDG 6 IWRM support programme, how does it work, How do I access the support programme." />
        <meta name="keywords" content="IWRM, SDG 6, SDG 6.5.1, water management, sustainable development, support programme IWRM, support programme SDG 6, support programme SDG 6.5.1" />
      </Helmet>
      <div className="main-container" style={{ padding: '0px 20px' }}>
        <div className="one-column">
          <div className="support-programme-title">IWRM Support Programme</div>
          <div className="two-columns">
            <div className="left-side">
              <div className="accordion-container">
              {
                data
                .sort((a, b) => a.ContentOrder - b.ContentOrder)
                .map((item) => (
                  <Accordion
                    key={item.Id}
                    expanded={expanded === `panel${item.Id}`}
                    onChange={handleChange(`panel${item.Id}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${item.Id}-content`}
                      id={`panel${item.Id}-header`}
                      style={customeStyle.accordionHeader}
                    >
                      <Typography style={customeStyle.accordionTitle}>{item.ContentHeader}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={customeStyle.accordionContent}>
                        {parse(item.ContentValue)}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))
              }
              </div>
            </div>
            <div className="right-side">
              <img src={media} alt="support-programme" style={{ width: '90%' }} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Support