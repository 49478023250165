import React, { useState, useEffect, Fragment, useCallback } from 'react'
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setPublicationData } from '../../hooks/usePublicationAction';
import {
  GET_PUBLICATION_REPORTS_PARAMETER_BY_TYPE,
  GET_ACTIVE_PUBLICATION_TYPES
} from '../../api/IWRMServices'
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import {
  Link,
  Stack,
  Box,
  Typography,
  Card,
  CardMedia,
  Tabs,
  Tab
} from '@mui/material';
import Search from '../../components/search/Search';
import { GAPush } from '../../utils/DownloadAnalytic'
import { useAppContext } from '../../context/AppContext';
import Spinner from '../../components/spinner/Spinner';

const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontSize: 12
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Publication = () => {
  const {
    state: { loading }, action: { setLoading }
  } = useAppContext()
  const dispatch = useDispatch();
  const storedData = useSelector(state => state.publication);
  const [value, setValue] = useState(1);
  const [reportType, setReportType] = useState('Global')
  const [cardData, setCardData] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [activeTabs, setActiveTabs] = useState([]);
  const navigate = useNavigate()
  const location = useLocation()

  const handleChange = (event, newValue) => {
    setValue(newValue + 1);
  };

  const publicationTypes = useCallback(async () => {
    try {
      let res = await fetch(GET_ACTIVE_PUBLICATION_TYPES);
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      const data = await res.json();
      return data;
    } catch (error) {
      console.error('Error fetching support programme contents:', error);
      return null;
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const result = await publicationTypes();
      if (result) {
        setActiveTabs(result);
      }
    };
    fetchData();
  }, [publicationTypes]);

  const newPublicationsData = useCallback(async (type) => {

    setLoading(true);

    try {
      if (storedData && storedData[type]) {
          setCardData(storedData[type]);
      } else {
          let res = await fetch(`${GET_PUBLICATION_REPORTS_PARAMETER_BY_TYPE}/${type}`);
          const data = await res.json();
          dispatch(setPublicationData(type, data));
          setCardData(data);
      }
    } catch (error) {
        console.error('Error fetching publication reports:', error);
    }
  
    setLoading(false);

  }, [setLoading, dispatch, storedData]);

  useEffect(() => {
    const path = location.pathname;
    function handlePathChange() {
      const activeTab = activeTabs.find(tab => tab.Link === path);
  
      if (activeTab) {
        setValue(activeTab.OrderValue);
        setReportType(activeTab.Type);
        newPublicationsData(activeTab.Type);
      }
    }
    handlePathChange();
  }, [location.pathname, activeTabs, newPublicationsData]);

  return (
    <Fragment>
      <Helmet>
        <title>IWRM Data Portal - Resource Library</title>
        <meta name="description" content="This page is include global, regional, result, and thematic report" />
        <meta name="keywords" content="IWRM, SDG 6, SDG 6.5.1, water management, sustainable development, publication & methodology IWRM, publication & methodology SDG 6, publication & methodology SDG 6.5.1, Resource Library IWRM, Resource Library SDG 6, Resource Library SDG 6.5.1" />
      </Helmet>
      <div className="main-container" style={{ padding: '0px 20px' }}>
        <div className="one-column">
          <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
            <Tabs value={value - 1} onChange={handleChange} aria-label="publication-type-tabs">
              {
                activeTabs?.sort((a, b) => a.OrderValue - b.OrderValue).map(item => item.IsActive && (
                  <CustomTab
                    label={item.Name}
                    {...a11yProps(item.OrderValue)}
                    onClick={() => {
                      setReportType(item.Type)
                      navigate(item.Link)
                      GAPush('tab_click', 'resource_library', `${item.Name}`)
                    }}
                    key={item.OrderValue}
                  />
                ))
              }
            </Tabs>
          </Box>
          <Search
            title="Search for a publications"
            onSearch={(e) => setSearchQuery(e.target.value.toLowerCase())}
          />
          {loading ? (
            <Spinner />
          ) : (
            activeTabs?.map(item => (
              <TabPanel value={value} index={item.OrderValue} key={item.ID}>
                <Stack
                  flexDirection={'row'}
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}>
                  {cardData.length !== 0 ? (
                    cardData.filter(item => 
                      (item.Name?.toLowerCase().includes(searchQuery) || '') ||
                      (item.Year?.toString().includes(searchQuery) || '') ||
                      (item.Author?.toLowerCase().includes(searchQuery) || '')
                    )
                    .sort((a, b) => a.OrderNumber - b.OrderNumber)
                    .map(report => {
                      return (
                        <Card
                          sx={{
                            display: 'flex',
                            width: '500px',
                            marginRight: '20px',
                            marginBottom: '20px'
                          }}
                          key={report.ID}
                        >
                          <Box>
                            <CardMedia
                              component="img"
                              sx={{ width: '220px', height: '100%' }}
                              image={report.Image}
                              alt={report.Name}
                            />
                          </Box>
                          <Box sx={{ flexDirection: 'column', padding: 1 }}>
                            {
                              reportType !== 'Methodology' && (
                                <Box>
                                  <Typography variant="subtitle1" style={{ color: '#09334B', fontSize: 12 }}>
                                    {report.Name}
                                  </Typography>
                                  <Stack sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
                                    <Typography variant="subtitle1" color="black" sx={{ flex: 1, color: '#09334B', fontSize: 12 }}>
                                      <b>Year</b> : {report.Year}
                                    </Typography>
                                    <Typography variant="subtitle1" color="black" sx={{ flex: 1, color: '#09334B', fontSize: 12 }}>
                                      <b>Author</b> : {report.Author === 'UN Environment' ? 'UNEP': report.Author }
                                    </Typography>
                                  </Stack>
                                </Box>
                              )
                            }
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 1 }}>
                              <Typography variant="subtitle1" color="black" sx={{ flex: 1, color: '#09334B', fontSize: 12 }}>
                                <b>Download</b> :
                              </Typography>
                              {
                                reportType !== 'Methodology' ? (
                                  <>
                                    <Stack flexDirection={'row'} flexWrap={'wrap'}>
                                      {
                                        report.ListPublicationsSubReportsParameters?.filter(res => !["(ES)", "(SM)", "(PPT)", "(CM)", "(VD)", "(VS)"].some(substring => res.Type.includes(substring))).length > 0 && (
                                          <Typography sx={{
                                            fontSize: 12,
                                            color: 'black',
                                            marginRight: 1
                                          }}>Full report : </Typography>
                                        )
                                      }
                                      {
                                        report.ListPublicationsSubReportsParameters?.filter(res => !["(ES)", "(SM)", "(PPT)", "(CM)", "(VD)", "(VS)"].some(substring => res.Type.includes(substring))).map(item => (
                                          <Link
                                            href={item.Link}
                                            target="_blank"
                                            key={item.ID}
                                            sx={{
                                              textDecoration: 'none',
                                              color: '#00aeef',
                                              fontSize: 12,
                                              marginRight: 1,
                                              width: 'fit-content'
                                            }}
                                            onClick={() => {
                                              GAPush(`${item.GA_Action}`, `${item.GA_Category}`, `${item.GA_Label}`)}
                                            }
                                          >
                                            {item.Label}
                                          </Link>
                                        ))
                                      }
                                    </Stack>
                                    <Stack flexDirection={'row'} flexWrap={'wrap'}>
                                      {
                                        report.ListPublicationsSubReportsParameters?.filter(res => res.Type.includes("(ES)")).length > 0 && (
                                          <Typography sx={{
                                            fontSize: 12,
                                            color: 'black',
                                            marginRight: 1
                                          }}>Executive summary : </Typography>
                                        )
                                      }
                                      {
                                        report.ListPublicationsSubReportsParameters?.filter(res => res.Type.includes("(ES)"))
                                        .sort((a, b) => {
                                          const languageOrder = ["English", "French", "Spanish", "Arabic", "Chinese", "Portuguese", "Russian"];
                                          return languageOrder.indexOf(a.Language) - languageOrder.indexOf(b.Language);
                                        })
                                        .map(item => (
                                          <Link
                                            href={item.Link}
                                            target="_blank"
                                            key={item.ID}
                                            sx={{
                                              textDecoration: 'none',
                                              color: '#00aeef',
                                              fontSize: 12,
                                              marginRight: 1,
                                              width: 'fit-content'
                                            }}
                                            onClick={() => {
                                              GAPush(`${item.GA_Action}`, `${item.GA_Category}`, `${item.GA_Label}`)}
                                            }
                                          >{item.Label}</Link>
                                        ))
                                      }
                                    </Stack>
                                    <Stack flexDirection={'row'} flexWrap={'wrap'}>
                                      {
                                        report.ListPublicationsSubReportsParameters?.filter(res => res.Type.includes("(VS)")).length > 0 && (
                                          <Typography sx={{
                                            fontSize: 12,
                                            color: 'black',
                                            marginRight: 1
                                          }}>Visual summary : </Typography>
                                        )
                                      }
                                      {
                                        report.ListPublicationsSubReportsParameters?.filter(res => res.Type.includes("(VS)"))
                                        .sort((a, b) => {
                                          const languageOrder = ["English", "French", "Spanish", "Arabic", "Chinese", "Portuguese", "Russian"];
                                          return languageOrder.indexOf(a.Language) - languageOrder.indexOf(b.Language);
                                        })
                                        .map(item => (
                                          <Link
                                            href={item.Link}
                                            target="_blank"
                                            key={item.ID}
                                            sx={{
                                              textDecoration: 'none',
                                              color: '#00aeef',
                                              fontSize: 12,
                                              marginRight: 1,
                                              width: 'fit-content'
                                            }}
                                            onClick={() => {
                                              GAPush(`${item.GA_Action}`, `${item.GA_Category}`, `${item.GA_Label}`)}
                                            }
                                          >{item.Label}</Link>
                                        ))
                                      }
                                    </Stack>
                                    <Stack flexDirection={'row'} flexWrap={'wrap'}>
                                      {
                                        report.ListPublicationsSubReportsParameters?.filter(res => res.Type.includes("(SM)")).length > 0 && (
                                          <Typography sx={{
                                            fontSize: 12,
                                            color: 'black',
                                            marginRight: 1
                                          }}>Social media cards : </Typography>
                                        )
                                      }
                                      {
                                        report.ListPublicationsSubReportsParameters?.filter(res => res.Type.includes("(SM)"))
                                        .sort((a, b) => {
                                          const languageOrder = ["English", "French", "Spanish", "Arabic", "Chinese", "Portuguese", "Russian"];
                                          return languageOrder.indexOf(a.Language) - languageOrder.indexOf(b.Language);
                                        })
                                        .map(item => (
                                          <Link
                                            href={item.Link}
                                            target="_blank"
                                            key={item.ID}
                                            sx={{
                                              textDecoration: 'none',
                                              color: '#00aeef',
                                              fontSize: 12,
                                              marginRight: 1,
                                              width: 'fit-content'
                                            }}
                                            onClick={() => {
                                              GAPush(`${item.GA_Action}`, `${item.GA_Category}`, `${item.GA_Label}`)}
                                            }
                                          >
                                            {item.Label}
                                          </Link>
                                        ))
                                      }
                                    </Stack>
                                    <Stack flexDirection={'row'} flexWrap={'wrap'}>
                                      {
                                        report.ListPublicationsSubReportsParameters?.filter(res => res.Type.includes("(PPT)")).length > 0 && (
                                          <Typography sx={{
                                            fontSize: 12,
                                            color: 'black',
                                            marginRight: 1
                                          }}>PowerPoint slides : </Typography>
                                        )
                                      }
                                      {
                                        report.ListPublicationsSubReportsParameters?.filter(res => res.Type.includes("(PPT)"))
                                        .sort((a, b) => {
                                          const languageOrder = ["English", "French", "Spanish", "Arabic", "Chinese", "Portuguese", "Russian"];
                                          return languageOrder.indexOf(a.Language) - languageOrder.indexOf(b.Language);
                                        })
                                        .map(item => (
                                          <Link
                                            href={item.Link}
                                            target="_blank"
                                            key={item.ID}
                                            sx={{
                                              textDecoration: 'none',
                                              color: '#00aeef',
                                              fontSize: 12,
                                              marginRight: 1,
                                              width: 'fit-content'
                                            }}
                                            onClick={() => {
                                              GAPush(`${item.GA_Action}`, `${item.GA_Category}`, `${item.GA_Label}`)}
                                            }
                                          >
                                            {item.Label}
                                          </Link>
                                        ))
                                      }
                                    </Stack>
                                    <Stack flexDirection={'row'} flexWrap={'wrap'}>
                                      {
                                        report.ListPublicationsSubReportsParameters?.filter(res => res.Type.includes("(CM)")).length > 0 && (
                                          <Typography sx={{
                                            fontSize: 12,
                                            color: 'black',
                                            marginRight: 1
                                          }}>Progress calculation methodology : </Typography>
                                        )
                                      }
                                      {
                                        report.ListPublicationsSubReportsParameters?.filter(res => res.Type.includes("(CM)"))
                                        .sort((a, b) => {
                                          const languageOrder = ["English", "French", "Spanish", "Arabic", "Chinese", "Portuguese", "Russian"];
                                          return languageOrder.indexOf(a.Language) - languageOrder.indexOf(b.Language);
                                        })
                                        .map(item => (
                                          <Link
                                            href={item.Link}
                                            target="_blank"
                                            key={item.ID}
                                            sx={{
                                              textDecoration: 'none',
                                              color: '#00aeef',
                                              fontSize: 12,
                                              marginRight: 1,
                                              width: 'fit-content'
                                            }}
                                            onClick={() => {
                                              GAPush(`${item.GA_Action}`, `${item.GA_Category}`, `${item.GA_Label}`)}
                                            }
                                          >
                                            {item.Label}
                                          </Link>
                                        ))
                                      }
                                    </Stack>
                                    <Stack flexDirection={'row'} flexWrap={'wrap'}>
                                      {
                                        report.ListPublicationsSubReportsParameters?.filter(res => res.Type.includes("(VD)")).length > 0 && (
                                          <Typography sx={{
                                            fontSize: 12,
                                            color: 'black',
                                            marginRight: 1
                                          }}>Watch the video : </Typography>
                                        )
                                      }
                                      {
                                        report.ListPublicationsSubReportsParameters?.filter(res => res.Type.includes("(VD)"))
                                        .sort((a, b) => {
                                          const languageOrder = ["English", "French", "Spanish", "Arabic", "Chinese", "Portuguese", "Russian"];
                                          return languageOrder.indexOf(a.Language) - languageOrder.indexOf(b.Language);
                                        })
                                        .map(item => (
                                          <Link
                                            href={item.Link}
                                            target="_blank"
                                            key={item.ID}
                                            sx={{
                                              textDecoration: 'none',
                                              color: '#00aeef',
                                              fontSize: 12,
                                              marginRight: 1,
                                              width: 'fit-content'
                                            }}
                                            onClick={() => {
                                              GAPush(`${item.GA_Action}`, `${item.GA_Category}`, `${item.GA_Label}`)}
                                            }
                                          >
                                            {item.Label}
                                          </Link>
                                        ))
                                      }
                                    </Stack>
                                  </>
                                ) : (
                                  report.ListPublicationsSubReportsParameters?.map(item => (
                                    <>
                                      {`${item.Name}`}
                                      <Link
                                        href={item.Link}
                                        target="_blank"
                                        key={item.ID}
                                        sx={{ textDecoration: 'none', color: '#00aeef', marginRight: 1 }}
                                        onClick={() => {
                                          GAPush('download', 'resource_library', `all_country_dataset_en_library ${item.Year}`)}
                                        }
                                      >
                                        {`${item.Language} (Excel)`}
                                      </Link>
                                    </>
                                  ))
                                )
                              }
                            </Box>
                          </Box>
                        </Card>
                      )
                    })
                  ) : (
                    <div style={{
                      padding: '100px 0',
                      border: '2px dashed #e9e9e9',
                      width: '100%',
                      background: '#F9F9F9',
                      borderRadius: '10px'
                    }}>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        filter: 'grayscale(1)',
                        opacity: 0.25
                      }}>
                          <img style={{
                            height: '100px',
                            width: '100px',
                          }} src='https://iwrmdataportal.unepdhi.org/static/media/clean-water-and-sanitation.9729f2ea138cf1e5ccb9a566e90f2993.svg' alt=''  />
                          <img style={{
                            height: '170px',
                            width: '170px',
                          }} src='https://iwrmdataportal.unepdhi.org/static/media/un-environment.8672d8916119c4e56f0e8d3631870ede.svg' alt=''  />
                      </div>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        fontSize: '18px', 
                        color: '#ababab',
                        fontWeight: 400
                      }}>
                          Coming soon
                      </div>
                    </div>
                    ) 
                  }
                  {cardData[0]?.ListPublicationsSubReportsParameters[0]?.Type.includes('ExtraResources') && (
                    <Card
                      sx={{
                        display: 'flex',
                        width: '500px',
                        height: '300px',
                        marginRight: '20px',
                        marginBottom: '20px'
                      }}
                    >
                      <Box>
                        <CardMedia
                          component="img"
                          sx={{ width: '220px', height: '100%' }}
                          image={'https://iwrmdataportal.unepdhi.org/static/media/news4.a9bd93c66ac19c462056.png'}
                          alt={''}
                        />
                      </Box>
                      <Box sx={{ flexDirection: 'column', padding: 1 }}>
                        <Box>
                          <Typography variant="subtitle1" style={{ color: '#09334B', fontSize: 12 }}>
                          Learn, explore and connect on designing and implementing Integrated Water Resources Management (IWRM) action towards a water secure world.
                          </Typography><br />
                          <Typography variant="subtitle1" style={{ color: '#09334B', fontSize: 12, fontWeight: 800 }}>
                          Access the website
                          </Typography>
                          <Link
                              href='https://iwrmactionhub.org/'
                              target="_blank"
                              rel="noopener noreferrer"
                              sx={{ textDecoration: 'none', color: '#00aeef', marginRight: 1 }}
                              onClick={() => {
                                GAPush('external_link', 'resource_library', 'extra_resources_IWRM_action_hub')}
                              }
                            >
                              Here
                          </Link>
                        </Box>
                      </Box>
                    </Card>
                  ) }
                </Stack>
              </TabPanel>
            ))
          )
          }
        </div>
      </div>
    </Fragment>
  )
}

export default Publication