import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography, Tabs, Tab } from '@mui/material';
import { styled } from '@mui/system';

import SdgBar from './SdgBar';
import ReportSdg from './ReportSdg';

const CustomTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    fontSize: 12
}));

function TabPanel({ children, value, index, ...other }) {
    const isVisible = value === index;

    return (
        <div
            role="tabpanel"
            hidden={!isVisible}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {isVisible && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const SdgSummary = ({
    country,
    setCountryName,
    setIsShowDownloadConfiguration,
    participatedInSurvey2017,
    participatedInSurvey2020,
    participatedInSurvey2023,
    setParticipatedInSurvey2017,
    setParticipatedInSurvey2020,
    setParticipatedInSurvey2023
}) => {

    const [tabValue, setTabValue] = useState(2);
    // const [year, setYear] = useState(2023);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const renderTabPanelContent = (year) => (
        <Stack flexDirection={'row'}>
            <Stack flex={5} justifyContent={'flex-start'}>
                <SdgBar country={country} year={year} />
            </Stack>
            <Stack flex={1}>
                <ReportSdg
                    year={year}
                    countryName={country}
                    setCountryName={setCountryName}
                    setIsShowDownloadConfiguration={setIsShowDownloadConfiguration}
                    participatedInSurvey2017={participatedInSurvey2017}
                    participatedInSurvey2020={participatedInSurvey2020}
                    participatedInSurvey2023={participatedInSurvey2023}
                    setParticipatedInSurvey2017={setParticipatedInSurvey2017}
                    setParticipatedInSurvey2020={setParticipatedInSurvey2020}
                    setParticipatedInSurvey2023={setParticipatedInSurvey2023}
                />
            </Stack>
        </Stack>
    );

    return (
        <Box>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="SDG Summary" indicatorColor="primary">
                {/* <CustomTab label="2017" {...a11yProps(0)} onClick={() => setYear(2017)} />
                <CustomTab label="2020" {...a11yProps(1)} onClick={() => setYear(2020)} />
                <CustomTab label="2023" {...a11yProps(2)} onClick={() => setYear(2023)} /> */}
                <CustomTab label="2017" {...a11yProps(0)}  />
                <CustomTab label="2020" {...a11yProps(1)}  />
                <CustomTab label="2023" {...a11yProps(2)}  />
            </Tabs>

            {[2017, 2020, 2023].map((year, index) => (
                <TabPanel key={year} value={tabValue} index={index}>
                    {renderTabPanelContent(year)}
                </TabPanel>
            ))}
        </Box>
    );
};

export default SdgSummary;
