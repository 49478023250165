import React from 'react'
// import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { Box, Typography } from '@mui/material'

const ownershipLaw = [
  {
    id: 1,
    title: <Typography fontSize='14px' fontWeight={600} marginBottom={1}>1. Use license</Typography>,
    content: [
      <Typography fontSize='12px' marginBottom={1}>Please feel free to browse the website. We grant you permission to view this website and to print or download material displayed on the website for your own personal, non-commercial use, provided that you agree to and accept without modification the notices, terms and conditions set forth in this agreement and provided that you keep intact all copyright, trademark and other proprietary notices.</Typography>,
      <Typography fontSize='12px' marginBottom={1}>You may not however copy, reproduce, republish, upload, transmit or distribute in any way the contents of this website, including the text, images, audio and video for public or commercial purposes, without written permission from us. You are also prohibited from using any content of this website in a frame on any other internet site in any way.</Typography>,
      <Typography fontSize='12px' marginBottom={1}>Your use of this website constitutes your agreement and acceptance without modification of the notices, terms and conditions set forth herein. In addition, as a condition of your use of this website, you represent and warrant to us that you will not use this website for any purpose that is unlawful, immoral or prohibited by these terms, conditions and notices.</Typography>
    ]
  },
  {
    id: 2,
    title: <Typography fontSize='14px' fontWeight={600} marginBottom={1}>2. User submissions</Typography>,
    content: [
      <Typography fontSize='12px'>Any communication or material that you transmit to the website by e-mail or otherwise is, and will be treated as non-confidential and non-proprietary information. Anything you transmit or post may be used by us for any purpose. You are prohibited from posting or transmitting to or from this Site any unlawful, threatening, libellous, defamatory, obscene, pornographic, or other material that may violate any law.</Typography>
    ]
  },
  {
    id: 3,
    title: <Typography fontSize='14px' fontWeight={600} marginBottom={1}>3. Links to and from other materials</Typography>,
    content: [
      <Typography fontSize='12px'>We may provide hyperlinks to third party sites. The linked sites are not under our control and in no way are we responsible for the content of any such linked site or for the content of any site linked to such linked site. We do not endorse companies or products to which it may provide hyperlinks and we reserve the right to note as such on our Site. We reserve the unilateral right to terminate any link or linking program at any time. If you decide to access any of the third party sites linked to this website, you do so at your own risk. Third parties may only provide hyperlinks to our main homepage. Any links to underlying sites require a prior written approval from us.</Typography>
    ]
  },
  {
    id: 4,
    title: <Typography fontSize='14px' fontWeight={600} marginBottom={1}>4. Content</Typography>,
    content: [
      <Typography fontSize='12px'>The content of the data portal is going to be updated periodically and the information and third part materials could be modified without previous notice to the user. UNEP-DHI and its partners do not warrant the accuracy and up to date information displayed in the data portal.</Typography>
    ]
  },
  {
    id: 5,
    title: <Typography fontSize='14px' fontWeight={600} marginBottom={1}>5. Intellectual property</Typography>,
    content: [
      <Typography fontSize='12px'>The texts, layouts, drawings, databases and other items on this website, as well as the website itself, are protected by copyright and by the right of the producer of the database. Some of the names, signs and logos on this website are protected trademarks or trade names. Nothing contained on the website should be construed as granting any license or right to use any trademark, displayed on the website without the written permission from us or such third party that may own the trademarks displayed on the website. Any copy, adaptation, translation, arrangement, modification, or any use whatsoever of the whole or of any part of this website of its protected elements, in any form and by any means, is strictly prohibited.</Typography>
    ]
  },
  {
    id: 6,
    title: <Typography fontSize='14px' fontWeight={600} marginBottom={1}>6. Data protection</Typography>,
    content: [
      <Typography fontSize='12px'>We collect and process information on the behaviour of the users of this Site for statistical purposes. The user has the right to oppose, free of charge, the processing for marketing purposes of the data concerning him, and has the right to access personal data and correct such data. For more information regarding the data that we collect and the measures we take to protect the privacy of our user data please refer to our Privacy Policy.</Typography>
    ]
  },
  {
    id: 7,
    title: <Typography fontSize='14px' fontWeight={600} marginBottom={1}>7. Cookies</Typography>,
    content: [
      <Typography fontSize='12px'>In order to improve your use of this site, we may use cookies on a limited scale. Cookies are small bits of information stored on your computer’s hard drive through your web browser by the web site. The use of cookies helps us to plan improvements and updates, and to analyse the use of and traffic on the site. Cookies do only record the Internet Protocol address of the visitors, and do not provide us with any personal information about the visitors of this site.</Typography>
    ]
  },
  {
    id: 8,
    title: <Typography fontSize='14px' fontWeight={600} marginBottom={1}>8. Liability</Typography>,
    content: [
      <Typography fontSize='12px'>Your use of and browsing in this Site is at your own risk. UNEP-DHI does not warrant that the software used for this Site, and the information, the on-line applications, or any other services provided by means of this Site are error-free, or that their use will be uninterrupted. UNEP-DHI expressly disclaims all warranties related to the above-mentioned subject matter, including, without limitation, those of accuracy, condition, merchantability and fitness for particular purpose. Notwithstanding anything to the contrary in this Site, in no event shall UNEP-DHI be liable for any loss of profits, revenues, indirect, special, incidental, consequential, or other similar damages arising out of or in connection with this Site or out of the use of any of the services proposed by means of this Site.</Typography>
    ]
  },
  {
    id: 9,
    title: <Typography fontSize='14px' fontWeight={600} marginBottom={1}>9. Updates</Typography>,
    content: [
      <Typography fontSize='12px'>We reserve the unilateral right to update, modify, change and alter our Terms and Privacy Policy at any time. All such updates, modifications, changes and alterations are binding on all users and browsers of our website and will be posted here.</Typography>
    ]
  },
  {
    id: 10,
    title: <Typography fontSize='14px' fontWeight={600} marginBottom={1}>10. Choice of law and forum provisions</Typography>,
    content: [
      <Typography fontSize='12px'>You agree that these Terms and your use of this website are governed by the laws of Denmark. You hereby consent to the jurisdiction and venue of the Municipal Court of Lyngby, Denmark in all disputes (a) arising out of, relating to, or concerning this website and/or these Terms, (b) in which this website and/or these Terms is an issue or a material fact, or (c) in which this website and/or these Terms is referenced in a paper filed in a court, tribunal, agency or other dispute resolution organisation. We have endeavoured to comply with all legal requirements known to us in creating and maintaining this website, but make no representation that materials on this website are appropriate or available for use in any particular jurisdiction. You are responsible for compliance with applicable laws. Any use in contravention of this provision or any provision of these Terms is at your own risk and, if any part of these Terms is invalid or unenforceable under applicable law, the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of these Terms shall govern such use.</Typography>
    ]
  },
]

const TermOfServices = () => {
  return (
    <div className="main-container" style={{ padding: '0px 20px' }}>
      {/* <Breadcrumb name="Terms of service" /> */}
      <div className="one-column">
        <div className="support-programme-title">Terms of Service</div>
        <Box marginBottom={2}>
          <Typography fontSize='14px' fontWeight={600} marginBottom={1}>
            Please read these terms and conditions (the “Terms”) carefully before using our website (“website”). By using this website you signify your consent to these Terms. If you do not agree to the Terms please do not use this website.
          </Typography>
          <Typography fontSize='12px'>The Terms addresses your legal rights and obligations and includes important disclaimers and choice of law and forum provisions. Please read carefully. The Terms apply to all websites of UNEP-DHI Centre, including sites for registered users.</Typography>
        </Box>
        {
          ownershipLaw.map(item => (
            <Box key={item.id} marginBottom={2}>
              {item.title}
              {item.content.map(res => (
                res
              ))}
            </Box>
          ))
        }
      </div>
    </div>
  )
}

export default TermOfServices